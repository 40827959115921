<template>
  <v-card>
    <v-container class="p-0">
      <v-card-title class="pb-0 pt-2">
        <span class="pl-1  primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Tipo expediente -->
            <v-col cols="12" md="8" class="pb-0">
              <v-autocomplete
                v-model="tiposExpSelected"
                :items="itemsTipoExp"
                label="Tipo de expediente"
                :rules="rules.required"
                return-object
                item-text="mTipoExpNom"
                item-value="mTipoExpId"
                outlined
                dense
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom max-width="30rem" color="">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        size="1.4rem"
                        class="pt-0"
                        v-on="on"
                        v-bind="attrs"
                        color="primary"
                      >
                        {{ infoIcon }}</v-icon
                      >
                    </template>
                    <span
                      >Utilizar la configuración múltiple de replicación por
                      Tipo de Expediente cuando el circuito del expediente
                      inicia en más de un sector diferente y estos sectores
                      generan la replicación al grabar o enviar por Remito.
                      También cuando una misma área de trabajo está activa en
                      diferentes sectores, generando la replicación al recibir
                      el Remito. Es esencial asegurar que cada expediente se
                      replique solo una vez a lo largo de todo su recorrido.
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <!-- sector -->
            <v-col cols="12" md="4" class="pb-0">
              <v-autocomplete
                v-model="sectorSelected"
                autocomplete="on"
                :items="itemsSector"
                label="Sector"
                :rules="rules.required"
                hide-details="auto"
                item-text="sectorNom"
                item-value="sectorId"
                @input="validaSectorInicial()"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- proceso -->
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-autocomplete
                v-model="procesoSelected"
                autocomplete="on"
                :items="itemsProceso"
                label="Proceso"
                :rules="rules.required"
                hide-details="auto"
                item-text="value"
                item-value="id"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row justify="center">
                <v-radio-group
                  v-model="emisionRecepcion"
                  hide-details="auto"
                  mandatory
                  row
                  dense
                  class=""
                >
                  <v-radio class="py-0" label="Emisión" value="E"></v-radio>
                  <v-radio class="py-0" label="Recepción" value="R"></v-radio>
                  <v-radio
                    class="py-0"
                    :disabled="!sectorInicial"
                    label="Al guardar el expediente"
                    value="X"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="
            !tiposExpSelected ||
              this.tiposExpSelected.length == 0 ||
              !sectorSelected ||
              this.sectorSelected.length == 0 ||
              !procesoSelected ||
              this.procesoSelected.length == 0 ||
              isFormValid == false
          "
          form="form"
          :loading="isLoadingBtn"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "NuevaConfigProceso",
  props: {
    itemsConsulta: {
      type: Object
    }
  },
  data: () => ({
    formEditTitle: "",
    emisionRecepcion: null,
    rules: rules,
    isLoadingBtn: false,
    emision: false,
    recepcion: false,
    guardarExpediente: false,
    sectorSelected: [],
    sectorOriginal: null,
    itemsSector: [],
    tiposExpSelected: [],
    itemsTipoExp: [],
    procesoSelected: [],
    itemsProceso: [],
    infoIcon: enums.icons.SNB_INFO,
    settingsIcon: enums.icons.SETTINGS,
    isFormValid: false,
    tiposNomenclador: [],
    tipoNomencladorSelected: null,
    nombreTipoPrestador: null,
    grabarPrecio: false,
    idProcTipo: null,
    sectorInicial: false
  }),
  created() {
    this.setSector();
    this.setTipoExp();
    this.setProceso();
    if (this.itemsConsulta) {
      this.idProcTipo = this.itemsConsulta.procTipoExpId;
      this.setConfigRepli();
    } else {
      this.newConfig();
    }
  },
  methods: {
    ...mapActions({
      getProcTipoExpById: "AdministracionSistema/getProcTipoExpById",
      getSectores: "AdministracionSistema/getSectores",
      getProcesoReplica: "AdministracionSistema/getProcesoReplica",
      getTipoExpById: "AdministracionSistema/getTipoExpById",
      saveProcTipoExp: "AdministracionSistema/saveProcTipoExp",
      getProcesos: "AdministracionSistema/getProcesos",
      saveTipoPrestador: "prestadores/saveTipoPrestador",
      getNomFarmacia: "prestadores/getNomFarmacia",
      setAlert: "user/setAlert"
    }),
    emisionChange() {
      if (this.emision == true) {
        this.recepcion == false;
        this.guardarExpediente == false;
      }
    },
    validaSectorInicial() {
      this.sectorInicial = this.sectorSelected?.esSectorInicial ?? false;
      if (!this.sectorInicial && this.emisionRecepcion == "X") {
        this.setAlert({
          type: "warning",
          message:
            "Sólo se permite configurar un sector inicial al guardar el expediente"
        });
        this.emisionRecepcion = "E";
      }
    },
    async setSector() {
      const res = await this.getSectores();
      this.itemsSector = res;
    },
    async setTipoExp() {
      if (this.itemsConsulta) {
        const resp = await this.getTipoExpById(
          this.itemsConsulta.procTipoExpId
        );
        this.itemsTipoExp = resp;
      } else {
        const resp = await this.getTipoExpById(0);
        this.itemsTipoExp = resp;
      }
    },
    async setProceso() {
      const proc = await this.getProcesos();
      this.itemsProceso = proc;
    },
    newConfig() {
      this.formEditTitle = "Nueva configuración del proceso de replicación";
    },
    async setConfigRepli() {
      if (this.itemsConsulta) {
        this.formEditTitle = "Editar configuración del proceso de replicación";
        const res = await this.getProcesoReplica(this.idProcTipo);
        this.tiposExpSelected = res.mTipoExp_id;
        let sector = this.itemsSector.find(x => x.sectorId == res.mSector_id);
        this.sectorSelected = sector;
        this.sectorInicial = sector.esSectorInicial;
        this.procesoSelected = res.mProc_id;
        this.emisionRecepcion = res.emisionRecepcion;
      }
    },
    async saveEdit() {
      this.isLoadingBtn = true;
      let mSectorId;
      if (
        this.itemsConsulta &&
        this.itemsConsulta.mSectorId !== this.sectorSelected
      ) {
        mSectorId = this.sectorSelected.sectorId;
      } else if (this.itemsConsulta) {
        mSectorId = this.itemsConsulta.mSectorId;
      } else {
        mSectorId = this.sectorSelected.sectorId;
      }
      let procId;
      if (
        this.itemsConsulta &&
        this.itemsConsulta.procId !== this.procesoSelected
      ) {
        procId = this.procesoSelected.id;
      } else if (this.itemsConsulta) {
        procId = this.itemsConsulta.procId;
      } else {
        procId = this.procesoSelected.id;
      }
      let tipoExpe;
      if (
        this.itemsConsulta &&
        this.tiposExpSelected.tipoExpNom !== this.tiposExpSelected.mTipoExpNom
      ) {
        tipoExpe = this.tiposExpSelected.mTipoExpId;
      } else if (this.itemsConsulta) {
        tipoExpe = this.tiposExpSelected;
      } else {
        tipoExpe = this.tiposExpSelected.mTipoExpId;
      }

      const data = {
        mProcTipoExp_id: this.itemsConsulta?.procTipoExpId,
        mTipoExp_id: tipoExpe,
        mSector_id: mSectorId,
        mProc_id: procId,
        emisionRecepcion: this.emisionRecepcion
      };
      try {
        const res = await this.saveProcTipoExp(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          this.closeModal();
        }
      } catch (error) {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadNew");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
